import React, { useEffect, useState, useContext } from 'react'
import { DateTime } from 'luxon'
import { Dialog, Image, Actions } from '../components/library'
import { navigate } from 'gatsby'
import { justPair } from '../utils/symptoms-bluetooth'
import { ImpactContext } from '../components/Layout'
import setBonesToBeAdded from '../utils/setBonesToBeAdded'
import patchUser from '../utils/patchUser'

function PairWearable() {
  const {userContext, wearableInitialAttrs, gettingStartedDone, gettingStarted, user } = useContext(ImpactContext)
  const [failed, setFailed] = useState(null)
  const [attrs, setAttrs] = useState(wearableInitialAttrs)
  const [boneID, setBoneID] = useState(null)

  useEffect(() => {
    if (!gettingStartedDone && !gettingStarted.includes('wearable')) {
      setBoneID(DateTime.now().toISO())
      justPair(setFailed, user)
    }
  }, [])

  useEffect(() => {
    if (failed === false && boneID !== null) {
      setBonesToBeAdded(2, boneID, 'Great job pairing your watch, you earned two bones!', false, 'Pairing successful!')
      patchUser(user?.id, userContext, {field_wearable_on: true})
      navigate('/getting-started')
    }
    if (failed === true) {
      setAttrs({
        handleClose: e => navigate('/getting-started'),
        title: 'Connection failed'
      })
    } else {
      setAttrs(wearableInitialAttrs)
    }
  }, [failed])

  if (gettingStartedDone) {
    navigate('/')
    return <></>
  }

  if (gettingStarted.includes('wearable')) {
    navigate('/getting-started')
    return <></>
  }

  return (
    <div>
      <Dialog id="sample-dialog" open={true} {...attrs}>
        <Image src={'/watch.png'} width="40%" aspectRatio={0.5} />
        {failed && (
          <Actions
            dismissOnClick={e => navigate('/getting-started')}
            dismissLabel="Cancel"
            submitOnClick={e => {
              setFailed(null)
              justPair(setFailed, user)
            }}
            submitLabel="Retry"
          />
        )}
      </Dialog>
    </div>
  )
}

export default PairWearable
